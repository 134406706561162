<template>
  <div class="video">
    <video src="https://vipro.fra1.cdn.digitaloceanspaces.com/Content/Trailer%20t5.mp4"  style="width: 90%; margin: 0 auto; transform:translate(5%)" ref="video"></video>
    <div class="buttons">
      
      <br>
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="100" fill="currentColor" class="bi bi-chevron-compact-down" viewBox="0 0 16 16" v-on:click="goDown">
        <path fill-rule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z"/>
      </svg>
    </div>
    <div class="volume">
      <svg v-if="paused"  v-on:click="unPause" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-play" viewBox="0 0 16 16">
        <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>
      </svg>
      <svg v-if="!paused"  v-on:click="pause" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 16">
        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
      </svg>
      <svg v-if="muted"  v-on:click="unMute" class="mute" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"  viewBox="0 0 16 16" style="transform: translateX(10px)">
        <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zM6 5.04 4.312 6.39A.5.5 0 0 1 4 6.5H2v3h2a.5.5 0 0 1 .312.11L6 10.96V5.04zm7.854.606a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"/>
      </svg>
      <svg  v-if="!muted" type="button" v-on:click="mute" class="mute" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"  viewBox="0 0 16 16"  style="transform: translateX(10px)">
        <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
        <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z"/>
      </svg>
      
    </div>
  </div>
</template>

<script>


export default {
  name: 'Video',
  methods: {
    goDown: function(){
      window.scrollTo(0,800)
    },
    unMute: function(){
      const videoEl = this.$refs.video
      videoEl.muted = false
      this.$store.commit('unMute')
    },
    mute: function(){
      const videoEl = this.$refs.video
      videoEl.muted = true
      this.$store.commit('mute')
    },
    unPause: function(){
      const videoEl = this.$refs.video
      videoEl.play()
      this.$store.commit('unPause')
    },
    pause: function(){
      const videoEl = this.$refs.video
      videoEl.pause()
      this.$store.commit('pause')
    },
    replay: function(){
      const videoEl= this.$refs.video
      videoEl.currentTime = 0;
      videoEl.play()
    }
  },
  computed:{
    muted: function () {
      return this.$store.state.muted
    },
    paused: function() {
      return this.$store.state.paused
    }
  },
  mounted(){
    const videoEl = this.$refs.video
    videoEl.muted = true
    videoEl.play()
    if(!this.$store.state.menuViewUntouched){
      window.scrollTo(0, window.innerWidth * 0.5)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.video {position: relative; width: 100%;}
.video {display: block;}
.video .buttons {position: absolute; bottom: 2%; z-index: 2; width: 100%; text-align: center; transform: translateX(-3px)}
svg{
  color:white;
  cursor:pointer;
  }
.video .volume{
  position: absolute; bottom: 6%; z-index: 3; right:10%; width: 20%; text-align: right; transform: translateX(-0px);
}

</style>
