import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  orientation: "front",
  cubes: [
    {id: 1, title:'WRSH', faculty: 'WISO', route:'advanced-organization-I',  orientation: 'front', colors:[{background: "#6D597A"}, {background: "#82718E"}, {background: "#9889A2"}, {background: "#ADA0B5"}, {background: "#C3B8C9"}, {background: "#D8D0DD"}, ]}, // Martin & Jessica
    {id: 2, title:'BMG', faculty: 'WISO', route:'statistik',  orientation: 'front', colors:[{background: "#2C3A4E"}, {background: "#3F4E64"}, {background: "#51627A"}, {background: "#647791"}, {background: "#768BA7"}, {background: "#899FBD"}, ]}, //Thomas
    {id: 3, title:'SBER', faculty: 'Phil.-hum.', route:'psychologische-therapie-in-der-praxis',  orientation: 'front' , colors:[{background: "#355070"}, {background: "#4A6A83"}, {background: "#5F8496"}, {background: "#759DA8"}, {background: "#8AB7BB"}, {background: "#9FD1CE"}, ]}, //Gianluca & Alexandra
    {id: 4, title:'SH', faculty: 'RW', route:'privatrecht-II',  orientation: 'front', colors:[{background: "#97495A"}, {background: "#A66271"}, {background: "#B67C88"}, {background: "#C595A0"}, {background: "#D5AFB7"}, {background: "#E4C8CE"}, ]}, // Nadja
    {id: 5, title:'LY', faculty: 'WISO', route:'public-sector-digitalization',  orientation: 'front', colors:[{background: "#AD1F21"}, {background: "#B93435"}, {background: "#C5484A"}, {background: "#D25D5E"}, {background: "#DE7173"}, {background: "#EA8687"}, ]},// Quentin
    {id: 6, title:'SW', faculty: 'WISO', route:'the-policy-and-politics-of-migration',  orientation: 'front', colors:[{background: "#9B491C"}, {background: "#AB5D32"}, {background: "#BB7148"}, {background: "#BB7148"}, {background: "#DA9875"}, {background: "#DA9875"}, ]}, //Stephanie
    {id: 7, title:'IK', faculty: 'WISO', route:'bildungserfolg-meritokratie-und-bildungsgerechtigkeit',  orientation: 'front', colors:[{background: "#BCA324"}, {background: "#C7B142"}, {background: "#D2BF60"}, {background: "#DCCD7E"}, {background: "#E7DB9C"}, {background: "#F2E9BA"}, ]}, //Ercan
    {id: 8, title:'DSGG', faculty: 'Vetsuisse', route:'lernprogramm-schweinebestand',  orientation: 'front', colors:[{background: "#70783F"}, {background: "#838B55"}, {background: "#969D6C"}, {background: "#AAB082"}, {background: "#BDC299"}, {background: "#D0D5AF"}, ]}, // Julio & Stephanie
    {id: 9, title:'LC', faculty: 'Phil.-nat.', route:'tutorium-nachhaltige-entwicklung',  orientation: 'front', colors:[{background: "#4C6B4D"}, {background: "#5C7D5E"}, {background: "#6D8F6E"}, {background: "#7DA17F"}, {background: "#8EB38F"}, {background: "#9EC5A0"}, ]} //Minh
  ],
  cubesBefore:[], // nur für die Animation relevant
  cubesAfter:[],  // nur für die Animation relevant
  menuView: 'big',      //nur für die Animation relevant
  menuViewUntouched: true,    //bestimmt ob Video angezeigt wird
  muted: true,
  paused: false,  
  
  projects: {                 //das ist eine Lise aller Projekte im JSON-Format
    
    //    +-------------------------+
    //    | Advanced Organization I |   
    //    +-------------------------+
    
    'advanced-organization-I':{         // hier beginnt das Projekt advanced organization I. "advanced-organization-I" ist ein slug und muss mit der route aus den würfeln übereinstimmen. Sonst kann das Projekt nicht gefunden werden.
      index: 1,                         //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      quotecolor: '#6D597A',
      backgroundLight: '#ADA0B5',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#978EB0',
      title: 'Advanced Organization I',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Die Vorlesung "Advanced Organization I" hat sich schon immer durch viel Interaktivität ausgezeichnet. Bis vor der Covid-Pandemie fand diese jedoch immer in Papierform statt. Mit der stetigen Zunahme von Studierenden ist auch die Arbeitsbelastung für die Betreuung des Kurses stark angestiegen. ',
        'Erfahren Sie nun, wie die eCoaches in diesem Projekt mit digitalen Tools die administrative Belastung verringerten und mit innovativen Konzepten auch während Covid Restriktionen einen hohen Grad an Interaktivität ermöglichten. '
      ],
      introductionimg: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/%281%29_Keyvisual_nathan-dumlao-xPHmmVKS8lM-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Stefanie Schumacher', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/StefanieSchumacher.png',     
        quote: 'Eine Vorlesung mit vielen Studierenden interaktiv gestalten und digital auf den neusten Stand bringen – mit diesem Ziel sind wir mit unseren eCoaches Jessica und Martin gestartet. Den Prinzipien für gute Hochschullehre folgend haben wir den ILIAS-Auftritt kreativ überarbeitet, Hilfsmittel zur Kollaboration integriert und aufwendige administrative Prozesse verschlankt. Die Zusammenarbeit hat mir viel Freude bereitet, die Vorlesung wirklich weitergebracht und das so erschaffene Angebot wurde auch von den Studierenden geschätzt. Ein grosses MERCI für den hervorragenden Einsatz!',
        orientation: 'right'},
        {name: 'David Hauser', role: '', imgsrc2:"", imgsrc: 'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/DavidHauser.png',
        quote: 'Die Zusammenarbeit mit den eCoaches Jessica und Martin habe ich als unkompliziert und lösungsorientiert empfunden. Es wurde keine Mühen gescheut, auf unsere Bedürfnisse und Wünsche einzugehen und diese entsprechend umzusetzen. Die erfrischenden Inputs der eCoaches schätzten wir sehr – sei es in der Umsetzung des optischen Auftritts oder der Gliederung unserer Vorlesung auf ILIAS.',
        orientation: 'left'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {name: 'Martin Widmer', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/MartinWidmer.png',
        quote: 'Bei all den digitalen Tools und Möglichkeiten ist es schnell möglich, dass der Wald vor lauter Bäumen nicht mehr sichtbar ist. Mit einem durchdachten Konzept helfen wir Ihnen, den Überblick zu behalten und den Studierenden ein förderndes digitales Lernerlebnis zu bieten.',
        orientation: 'right'},
        {name: 'Jessica Riediker', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/JessicaRiediker.png',
        quote: 'Im Zeitalter der Digitalität stehen uns in der Lehre so viele Möglichkeiten offen. Ich wünsche mir barrierefreie, begeisternde Lehrveranstaltungen mit Hybridkonzepten. Damit Lernen einladend und engagierend bleibt.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {                        
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Mitte Juli",
          content:
            "In einem ersten Treffen werden die Anforderungen an das fertige Produkt festgelegt. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/%282%29_MItte%20Juli_maddi-bazzocco-50u9zYeHNu4-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Ende Juli",
          content:
            "Die eCoaches haben ein Konzept entworfen und stellen dies vor. Die Entwicklung eines Prototyps kann beginnen.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/%283%29_Ende%20Juli_new-data-services-0tSiofevpUs-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "Mitte September",
          content:
            "Das Semester beginnt, die Studierenden nutzen die neue Infrastruktur",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/%284%29_Ende%20August_anton-shuvalov-9EUwYOG3MVQ-unsplash_ccexpress.jpeg"
        }
      ],
      //TODO: Insert correct images
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/01.png', orientation: 'left', title:'ILIAS Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#978EB0', text:'Ein neu gestalteter ILIAS-Kursraum verschafft nun einfachen und intuitiven Zugang zu allen relevanten Materialien. Als LMS (Learning Management System) ist ILIAS die zentrale Anlaufstelle für alle digitalen Aspekte der Vorlesung. Über die Plattform kann alles- von den Podcasts für die Vorlesung bis zu den Formularen für die Gruppenbildung- gefunden werden.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/02.png', orientation: 'right', title:'Tools',
          background:'#ADA0B5', text:'Detaillierte Anleitungen zu den im Kurs verwendeten digitalen Tools vereinfachen den Einstieg für die Studierenden. Während der Vorlesung bleibt damit mehr Zeit für Inhalte, da ausführliche technische Erklärungen wegfallen. Dadurch verringern sich ansonsten notwendige technische und administrative Supportleistungen um ein Vielfaches.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/1/03.png', orientation: 'left', title:'Merkblatt',
          background:'#978EB0', text:'Zusätzlich zum digitalen Lernkonzept haben die eCoaches auch ein Merkblatt mit allen relevanten Informationen zur Reproduktion dieses Erfolges in den kommenden Jahren realisiert.' }
      ]
    }, // Ende des Projektes advanced Organization I
    
    
    
    
    
    'statistik':{
      index: 2,
      quotecolor: '#355070',
      backgroundLight: '#b1c1d2',
      backgroundDark: '#9bb0cc', // TODO: Diese Farbe stimmt noch nicht
      title: 'Statistik',
      introductions:[
        'Rund 80 Studierende besuchen jährlich den Kurs "Kombinatorik & Wahrscheinlichkeitsrechnung" und jeweils 450 Studierende absolvieren "Einführung in die Statistik für Wirtschafts- und Sozialwissenschaften". Statistik wirkt häufig abstrakt und wenig greifbar. Ziel des eCoach-Projekt war es, digitale Lösungen für diese zwei Lehrveranstaltungen zu suchen, um die Kursinhalte möglichst interaktiv und anschaulich zu gestalten. Ebenso stand das studierendenzentrierte Lernen im Fokus: Den Kursteilnehmern wurden mittels verschiedener digitaler Tools Möglichkeiten geschaffen, wie sie sich individuell und im eigenen Tempo mit dem Unterrichtsstoff auseinandersetzen können.'
      ],
      introductionimg: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/2/%281%29_Keyvisual_isaac-smith-AT77Q0Njnt0-unsplash_ccexpress.jpeg",
      lecturers:[
        {name: 'Prof. Dr. David Ginsbourger', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Thomas/DavidGinsbourgerSW.png',
        quote: 'ILIAS bietet unglaublich viele Chancen, um den Unterricht digital zu bereichern. Ich bin sehr froh, dass iLUB mich im Rahmen des eCoach-Projekts unterstützte, um dieses Potenzial auszuschöpfen. Mein Wunsch ist, dass möglichst viele Studierende sowie Dozierende künftig von einer guten Digitalisierung der Lehre profitieren können.',
        orientation: 'right'},
        {name: 'Anja Mühlemann', role: '', imgsrc2:"", imgsrc: 'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Thomas/AnjaM%C3%BChlemannSW.png',
        quote: 'Die Digitalisierung eröffnet neue Perspektiven in der Hochschullehre. Der Unterricht lässt sich interaktiver und anschaulicher gestalten. Die Studierenden können besser gefördert werden, indem sie die Möglichkeit erhalten, ihre Lernprozesse individuell und flexibel zu gestalten. In meinem Fall geschieht dies etwa durch die Verwendung von ILIAS-Quizzes oder der Plattform wiso.education, mit welcher die Eigenschaften statistischer Verteilungen erlernt werden können. Das eCoach-Projekt hat mir insbesondere dabei geholfen, die vielen Möglichkeiten, welche digitale Lehre und im Speziellen ILIAS bietet, kennenzulernen und anzuwenden',
        orientation: 'left'},
        {name: 'Thomas Blümli', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Thomas/ThomasBl%C3%BCmliSW.png',
        quote: 'Statistik erscheint oft abstrakt und kaum greifbar. Ich wünsche mir, dass in statistischen Lehrveranstaltungen digitale Tools Einzug halten, die es den Studierenden ermöglichen, statistische Zusammenhänge dynamisch und interaktiv zu erlernen.',
        orientation: 'right'}
      ],
      timelineItems:[
        {
          index: 0,
          title: "Kennenlernen, Ideen sammeln (Juni- Juli 2021)",
          content:
            "Im Rahmen mehrerer Sitzungen haben David Ginsbourger, Anja Mühlemann und ich Ideen entwickelt und gesammelt. Wir haben definiert, welche davon wir zur Umsetzung bringen möchten.",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/2/%286.2%29_Kennenlernen,%20Ideen%20sammeln_%20%28Juli%29_charles-deluvio-Lks7vei-eAg-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Kursraum gestalten, erste Semesterwoche (August 2021)",
          content:
            "Das Einrichten der Kursräume haben die beiden Dozierenden mehrheitlich selbst vorgenommen, um eigene Erfahrungen mit den ILIAS-Tools zu sammeln. Ich stand dabei unterstützend zur Seite.",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/2/%286.3%29_Kursraum%20gestalten,%20erste%20Semesterwoche_kvalifik-5Q07sS54D0Q-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "Optimierung / Weiterentwicklung des Kurses (Oktober- November 2021)",
          content:
            'Im Verlauf des Semesters entwickelten sich weitere Ideen zur Weiterentwicklung der Lehre. Im Fokus stand dabei insbesondere die Gestaltung einer "Repetitionswoche", welche den Studierenden die Möglichkeit gab, ihren eigenen Lernstand zu überprüfen.',
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/2/%286.4%29_Optimierung,%20Weiterentwicklung%20des%20Kurses_ross-sneddon-sWlDOWk0Jp8-unsplash.jpeg"
        },
        {
          index: 3,
          title: "Erstellung des Videos über die Lehrveranstaltung (Dezember 2021- Januar 2022)",
          content:
            'Wir entschieden, die Dokumentation des Projekts in Videoform vorzunehmen. Die Produktion dieses Videos stand in den Monaten Dezember und Januar im Fokus.',
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/2/%286.5%29_%20Erstellung%20der%20Videos%20u%CC%88ber%20die%20Lehrveranstaltung_jakob-owens-EWaoo-i7qIs-unsplash_ccexpress.jpeg"
        }
      ],

      //TODO: insrert correct images
      contents:[
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/2/01.jpg', orientation: 'left', title:'ILIAS-Kursraum',
          background:'#9bb0cc', text:'Die beiden ILIAS-Kursräume der Lehrveranstaltungen "Kombinatorik und Wahrscheinlichkeitsrechnung" sowie "Statistik für Wirtschafts- und Sozialwissenschaftler" sind in ihrer Struktur identisch aufgebaut. Ziel war es, ein ansprechendes und übersichtliches Design zu schaffen. Dies wurde insbesondere mittels Gestaltung einer Kacheloptik erreicht.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/2/02.jpg', orientation: 'right', title:'ILIAS-Quizzes',
          background:'#b1c1d2', text:'Die Übungsserien, welche bis anhin als PDF den Studierenden abgegeben worden waren, wurden in Form von ILIAS-Quizzes digitalisiert.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/2/03.png', orientation: 'left', title:'Plattform "wiso.education"',
          background:'#9bb0cc', text:'Die auf einer Shiny-App basierende Plattform "wiso.education" veranschaulicht statistische Verteilungen. Die Studierenden erlernen mittels der Plattform die Eigenschaften der Verteilungen, indem sie deren Parameter interaktiv verändern können und die daraus folgenden Veränderungen grafisch sehen.' }
      ]
    },



    'psychologische-therapie-in-der-praxis':{         // hier beginnt das Projekt advanced organization I. "advanced-organization-I" ist ein slug und muss mit der route aus den würfeln übereinstimmen. Sonst kann das Projekt nicht gefunden werden.
      index: 3, 
      quotecolor: '#2C3A4E',                        //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#8DBCBE',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#77A1AB',
      title: 'Psychologische Therapie in der Praxis',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Schön, dass Sie bei unserem Projekt vorbeischauen!', 'Der ILIAS-Kursraum vom Seminar "Psychologische Therapie in der Praxis" vom Herbstsemester 2020 war sehr schlicht gehalten und wurde ausser einem Wiki vor allem als Dateienablagen von den herangehenden Studierenden und Therapeuten/-innen genutzt.',
        'Fürs Herbstsemester 2021 wollten die Kursleitenden etwas Neues ausprobieren und somit kamen wir Alexandra Szalai und Gianluca Bernasconi, ins Team von Daniel Regli und Eveline Frischknecht.',
        'Unser Ziel bestand darin, den Kursraum nicht nur gestalterisch aufzuwerten, sondern auch mit einer gezielten Ergänzung von hilfreichen Lerntools die fördernde Beteiligung im ILIAS-Kursraum der Teilnehmenden anzustreben. ',
        'Gleichzeitig bestand eine weitere Aufgabe darin, den Datenschutz im ganzen Kursraum zu gewährleisten, damit auch Dateien und Lehrvideos online geteilt werden können. Etwas im Herbstsemester 2020 beispielsweise nicht möglich war, da hier die nötigen Fachkenntnisse gefehlt haben',
        'Neben diesen nötigen Fachkenntnisse bringen wir aber noch weitere Fähigkeiten mit, von denen SIE ab sofort profitieren können.'
      ],
      introductionimg: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/3/%281%29_Keyvisual_Counseling_priscilla-du-preez-aPa843frIzI-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Eveline Frischknecht', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Gianluca_Alex/EvelineFrischknechtSW.png',    // TODO get real names 
        quote: 'Wir haben sehr viel gelernt, auch über die ILIAS-Tools. Durch das Projekt erhielten wir viele neue Inputs für unsere Veranstaltung.', 
        orientation: 'right'},
        {name: 'Dr. Daniel Regli', role: '', imgsrc2:"", imgsrc: 'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Gianluca_Alex/DanielRegliSW.png',
            quote: 'Es war eine konstruktive Zusammenarbeit. Es war faszinierend, mit zwei anderen Personen zusammenzuarbeiten, die an einem anderen Ort stehen. Wir möchten auf jeden Fall das Projekt eCoaches weiterempfehlen.',
        orientation: 'left'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
          {
        name: 'Gianluca Bernasconi ', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Gianluca_Alex/GianlucaBernasconiSW.png',
        quote: 'Als eCoach steht für mich an oberster Stelle der gemeinsam gewonnene Nutzen aus dem Coaching Angebot. Nicht nur für die Studierenden, sondern auch für Sie als Dozent/-in. Gemeinsam werden wir mit Ihnen digitale Lösungen für eine zukunftsorientierte Lehre finden und kreieren, von denen Sie und Ihre Arbeitskollegen/ -innen garantiert profitieren können.',
        orientation: 'right'},
        {name: 'Alexandrea Szalai', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Gianluca_Alex/AlexandraSzalaiSW.png',
        quote: 'Als eCoach ist mir eine zielgerichtete Kommunikation und eine gute Zusammenarbeit wichtig. Mein Wunsch ist es Ideen und Vorstellungen zu einer digitalen Realität werden zu lassen.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juni – Juli 2021",
          content:
            "In ein paar Sitzungen wurde vor allem mittels Brainstorming schnell erfasst, was die Dozenten von uns wünschen. Gemeinsam haben wir weitere Termine abgemacht und gegenseitig abgesprochen, um die nächsten Schritte zu lernen. Es war gleich zu Beginn eine gute Atmosphäre zwischen allen Beteiligten im neuen 4er Team spürbar. ",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%282%29_Kennenlernphase%20mit%20Dozierenden_jason-goodman-Oalh2MojUuk-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Juli 2021",
          content:
            "Nachdem wir uns alle im Team besser kennengelernt haben, konnten wir mittels Brainstorming mehrere Konzepte entwickeln, die wir unter uns eCoaches anschliessend verfeinert haben. Bei den anschliessenden Gruppensitzungen wurde im Team das beste Konzept gewählt und folgend erste Schritte umgesetzt.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%283%29_Konzept_%28Juli%29_mario-gogh-VBLHICVh-lI-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "Juli – August 2021",
          content:
            "Diese Phase beanspruchte am meisten Zeit, da bei Zwischensitzungen immer wieder neue kleine Ergänzungen dazukamen. Gleichzeitig ist dies auch die Phase gewesen, in der die Dozierenden die ersten Tools besser kennengelernt haben, da wir unsere Fortschritte im ILIAS-Kursraum immer mit ihnen besprochen haben.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%284%29_Umsetzung_jon-tyson-r9T0LZv8xWQ-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "September 2021",
          content:
            "Nachdem alles umgesetzt wurde, konnten wir den Kurs für alle Teilnehmer öffentlich machen. Ab diesem Punkt hatten alle Teilnehmer Zugriff zu den allgemeinen Ordnern plus zum individuellen Gruppenordner. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%285%29_Kursraum%20online%20schalten_ashkan-forouzani-BWt46mFZRG8-unsplash_ccexpress.jpeg"
        },
        {
          index: 4,
          title: "September – Dezember 2021",
          content:
            "Immer wieder gab es kleine Aufträge seitens der Dozierenden, welche in den neuen Kurs eingebettet wurden. Das Schöne daran ist, dass es nicht nur eine Variante gibt, sondern mehrere Tools zum gleichen/ähnlichen Endprodukt führen können. Somit konnten verschiedene Tools zusammen ein- und umgesetzt werden. Gleichzeitig konnte die Lernkurve gesteigert werden durch immer wiederkehrende Nuancen von ähnlichen Aufträgen.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%286%29_Anpassungen%20und%20Erga%CC%88nzungen_ryoji-iwata-5siQcvSxCP8-unsplash_ccexpress.jpeg"
        },
        {
          index: 5,
          title: "Dezember 2021",
          content:
            "In dieser letzten Sitzung wurden nochmals kleine Fragen gestellt zur Problemlösung mittels eines Tools auf ILIAS. Es wurde sehr schnell eine Lösung gefunden und man hat gemerkt, wie die Dozentin aufgrund der Erfahrung mit den kennengelernten Tools sehr schnell selber auf die Lösung kam.            ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/%287%29_Letzte%20Sitzung_riccardo-annandale-7e2pe9wjL9M-unsplash_ccexpress.jpeg"
        }
      ],
      //TODO: von Gianluca und Alexandra fehlt immer noch der eigentliche Inhalt bzw. ist zu vage
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/01.png', orientation: 'left', title:'Kursraum',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#77A1AB', text:'Diverse Tools verwendet</p><p>Wirkt viel anschaulicher</p><p>Bessere Orientierung für Studierende durch klar definierte Ordner und Struktur im Kursraum</p><p>Grosser Wert auf Datenschutz gelegt durch beschränkte Gruppen und Passwörter</p><p>Lernkurve unterstützt durch kurze, aber anspruchsvolle Zwischentests ("Test" als Tool verwendet) die Studierenden</p>' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/3/02.jpg', orientation: 'right', title:'Merkblatt',
          background:'#8DBCBE', text:'Als PDF aber auch als ILIAS-Ordner</p><p>Enthält alle wichtigen Tools, welche im Verlauf der Zusammenarbeit mit den beiden Dozierenden Daniel Regli und Eveline Frischknecht verwendet und gelernt wurde, richtig einzusetzen</p>' }
      ]
    },
    'privatrecht-II':{         
      index: 4,
      quotecolor: '#97495A',            //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#D5AFB7',       //Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#B56576',
      title: 'Privatrecht II: Einleitungstitel zum ZGB',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Schön, dass Sie zu unserem Projekt gefunden haben!',
        'Die Veranstaltung „Privatrecht II: Einleitungstitel ZGB“ hat im Vorjahr das Konzept geändert. Neu wurden die Wochenstunden in Selbststudium und vertiefende Präsenzveranstaltung eingeteilt. Die Studierenden sollen sich durch wöchentliche Vorbereitungsaufträge und Repetitionsfragen Basiswissen aneignen. Im Zentrum der Kontaktveranstaltung steht dann die Vertiefung des angeeigneten Stoffs. Dies war Anknüpfungspunkt für unser eCoach-Projekt. Wir wollten eine digitale Struktur erschaffen, welche die Studierenden selbständig durch das Semester führt, das Selbststudium und die Präsenzveranstaltung besser verknüpfen, Repetitionsfragen digitalisieren und unser Projekt evaluieren. '
       // 'Das war der Anknüpfungspunkt für unser eCoach-Projekt. Immer noch überzeugt von der Qualität des Konzepts haben wir uns die Umsetzung im Detail angeschaut.',
        //'<ul><li>Digitale Struktur erschaffen, welche Studierende selbstst&auml;ndig durch das Semester f&uuml;hrt<br>Selbststudium und Pr&auml;senzveranstaltung besser Verkn&uuml;pfen<br>Repetitionsfragen digitalisieren<br>Spezialevaluation erstellen</li></ul>'
      ],
      introductionimg: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%281%29_Keyvisual_jez-timms-8muUTAmcWU4-unsplash_ccexpress.jpeg",      // TODO: change picture
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Prof. Dr. Stephanie Hrubesch', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Nadja/StephanieHrubeschSW.png',    
        quote: 'Die Zusammenarbeit mit der eCoachin war sehr befruchtend und wir konnten gemeinsam ein tolles Lehr- und Lernkonzept erstellen und einen ansprechenden Kursraum schaffen.', 
        orientation: 'right'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
          {
        name: 'Nadja Spaar', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Nadja/NadjaSpaarSW.png',
        quote: 'Wir haben eine digitale Struktur in ILIAS erschaffen, welche die Studierenden bewusst durch das Semester leiten soll. Dadurch konnten wir das Zusammenspiel zwischen Selbststudium und vertiefender Präsenzveranstaltung verbessern und optimal unterstützen.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juni 2021",
          content:
            "In einem ersten Schritt haben Stephanie und ich über das bereits bestehende Konzept gesprochen. Dabei haben wir versucht herauszufiltern, was wir beibehalten möchten und was weiterentwickelt werden soll.",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%286.2%29_Konzeptu%CC%88berarbeitung_rifqi-ali-ridho-VlXlW77swwY-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Juli-September 2021",
          content:
            "Die Umsetzung nahm sehr viel Zeit in Anspruch. Da wir uns aber im ersten Schritt gut abgesprochen hatten, brauchte es nur wenig Austausch, um sich auf dem neusten Stand zu halten und kleinere Änderungen vorzunehmen.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%286.3%29_Umsetzung_fabio-comparelli-uq2E2V4LhCY-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "September 2021",
          content:
            "Rechtzeitig für den Semesterstart konnte der Kursraum allen Studierenden zugänglich gemacht werden.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%286.4%29_Kursraum%20online%20schalten_isaac-li-shung-tan-ZVr9_4sX8k4-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "September – Dezember 2021",
          content:
            "Auch während des Semesters hat sich das Projekt weiterentwickelt und es mussten hie und da kleinere Sachen angepasst werden.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%286.5%29_Anpassungen%20und%20Erkla%CC%88rungen_edge2edge-media-x21KgBfOd_4-unsplash_ccexpress.jpeg"
        },
        {
          index: 4,
          title: "Ende September – Dezember 2021",
          content:
            "Um konkrete Rückmeldungen über unser eCoach-Projekt zu erhalten, haben wir eine Spezialevaluation erstellt, diese von den Studierenden ausfüllen lassen und in einem nächsten Schritt ausgewertet.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/%286.6%29_Spezialevaluation_nguyen-dang-hoang-nhu-cbEvoHbJnIE-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/01.png', orientation: 'left', title: 'ILIAS-Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#D5AFB7', text:'Der ILIAS-Kursraum hat das Ziel, Studierende selbsterklärend durch das Semester zu führen. Auf der Startseite finden sich allgemeine Informationen zum Konzept, zum Selbststudium, zu den vertiefenden Präsenzveranstaltungen, zu den Lektüren etc. Scrollt man weiter, findet sich eine Ordnerstruktur, welche die Semesterwochen repräsentieren. In jedem Wochenordner sollen die Studierenden alles Wesentliche für die entsprechende Woche finden, also unter anderem die wöchentlichen Informationen und Aufgaben und der dazugehörige Repetitionstest.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/02.png', orientation: 'right', title: 'Repetitionstest',
          background:'#B56576', text:'Herzstück der einzelnen Wochenaufgaben sind die neu digitalisierten Repetitionsfragen, die Repetitionstests. Damit können sich die Studierenden jede Woche selbst testen und überprüfen, ob sie das sich im Selbststudium angeeignete Wissen verstanden haben.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/4/03.jpg', orientation: 'left', title:'Evaluation',
          background:'#D5AFB7', text:'Um überprüfen zu können, ob wir in der Umsetzung positive Veränderungen herbeiführen konnten, haben wir eine Spezialevaluation erstellt. Damit konnte spezifisch auf die veränderten Projektteile eingegangen und konkretes Feedback eingefordert werden. Dabei gab es vonseiten der Studierenden konstruktive Kritik und Verbesserungsvorschläge, jedoch ist das Feedback erfreulicherweise sehr positiv ausgefallen. ' },
      ] // TODO: add pictures, decide about titles
    },

    'public-sector-digitalization':{          // TODO: insert Content
      index: 5,
      quotecolor:'#AD1F21',                         //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#ED9798',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#E56B6F',
      title: 'Public sector digitalization',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Herzlich willkommen unserem Projekt für das Seminar "Public Sector Digitalization"',
        'Da dieses Seminar zum ersten Mal an der Universität Bern gelehrt wird, war die Konzipierung des ILIAS-Kursraumes sowie auch die englische Sprache eine Herausforderung. Der Dozent Srinivas Yeramsetti, und ich, Quentin gaben jeweils unseres Bestes, um einen intuitiven, zulänglichen, aber zugleich auch einen korrekten und geordneten ILIAS-Kursraum entstehen zu lassen. Es freut uns sehr, Euch diesen als fertiges Produkt vorstellen zu dürfen.'
      ],
      introductionimg: "https://vipro.fra1.digitaloceanspaces.com/Content/5/%281%29_Keyvisual_sajad-nori-oPRU2Cne0Pw-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Srinivas Yerramsetti', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Quentin/SrinivasYerramsettiSW.jpeg',     
        quote: 'It\'s hardly possible to overstate the value, in the present state of human improvement, of placing human beings in contact with other persons dissimilar to themselves, and with modes of thought and action unlike those with which they are familiar. Such communication has always been... one of the primary sources of progress. – John S. Mill', 
        orientation: 'right'},                                                                                                                                         // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
        {name: 'Quentin Lauer', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Quentin/QuentinLauerSW.png',
        quote: 'Der Austausch zwischen Dozenten und Studierenden ist meiner Meinung nach für eine gute Lehre entscheidend. Vor,während und nach der Vorlesung. Ich wünsche mir, dass diese Form der Interaktion stets vom Dozenten und von der Dozentin in Richtung Studierenden beachtet wird.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juli – August 2021",
          content:
            "Srinivas und ich kommen als Tandem zusammen. Srinivas und ich spielten von Anfang an mit der Idee, den Kursraum einfach, aber intuitiv zu halten. Dabei lehnen wir uns an bereits vorhandenen, häufig gebrauchten Mustern der Lehre an. Die Grundidee ist es, Herkömmliches mit Neuem zu kombinieren - sodass einerseits die Studierenden neue Perspektiven erleben können, jedoch sich jederzeit auf dem Kursraum zurechtfinden.",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%282%29_Zusammenkommen%20und%20Planung_cytonn-photography-n95VMLxqM2I-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "August – September 2021",
          content:
            "Ich erstelle die ersten Konzepte und gestalte die ersten ILIAS-Seiten danach. Nachdem wir uns auf ein Modell geeinigt haben, führe ich dieses durch.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%283%29_Konzipieren%20und%20Umsetzung_brands-people-Ax8IA8GAjVg-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "September 2021",
          content:
            "Nach einigen Tests und letztem Feinschliff schalten wir den Kursraum gemeinsam pünktlich zum Semesterbeginn für die Studierenden auf. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%284%29_Aufschalten_drew-patrick-miller-_o6AAx9dl_Y-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "September-Dezember 2021",
          content:
            "Seit Semesterbeginn werden regelmässig die betreffenden Präsentationen, Arbeitsblätter und Dokumente hochgeladen. Ich schaue dazu, dass die ILIAS-Seite stets aktuell gehalten wird und verbessere hier und da. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/%285%29_Uploads,%20Aktualisierungen%20und%20Optimierungen_edge2edge-media-x21KgBfOd_4-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/02.png', orientation: 'left', title:'Kursraum',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#ED9798', text:'Der Kursraum hat eine einfache Struktur - er ist in seinem Kernstück eine simple Website mit allen nötigen Infos. ' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/5/01.png', orientation: 'right',title:'Seminar Paper und Evaluation ',
          background:'', text:'Seminar Paper und Evaluation passieren direkt auf ILIAS. Hier können die Studenten ihre Arbeiten direkt einhändigen. Ganz wichtig ist der Punkt, dass der Syllabus eingefügt sind und Studierende so jeweils schauen, wie sich der Ablauf ergeben wird, um sich frühzeitig auf die Vorlesung vorbereiten zu können. Die Units enthalten die wichtigsten Studien als Link zum Download. Lesen steht im Fokus dieser Mastervorlesung. ' }
      ]
    },


    'the-policy-and-politics-of-migration':{         
      index: 6, 
      quotecolor: '#9B491C',                        //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#EFC1A9',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#EAAC8B',
      title: 'The Policy and Politics of Migration',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Das Proseminar "The Policy and Politics of Migration" wurde bereits im HS2020 angeboten. Im HS2021 sollte die Onlinelehre verbessert werden. Dazu sollten bestehende Online-Elemente weiterentwickelt und neue aufgenommen werden. Ziel war, eine bessere Gestaltung und Aufbau des ILIAS-Kursraumes zu bieten. Inputvideos sollten abwechslungsreicher gestaltet werden. Zudem produzierten die Studierenden eigene Lernvideos, die sie auf ILIAS hochladen mussten. Um die Qualität der Videos sicherzustellen, war eine Hilfestellung notwendig.'
      ],
      introductionimg: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/%281%29_Keyvisual_marco-oriolesi-wqLGlhjr6Og-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Théoda Woeffray', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Stephanie/Th%C3%A9oda%20Woeffray.png',     
        quote: 'Zu den zentralen Aspekten, die das selbstständige Lernen fördern, gehören für mich unter anderem auch ein übersichtlicher und ansprechender ILIAS-Kursraum, in welchem die Studierenden sich Lerninhalte aneignen können, aber auch Interaktion ermöglicht wird. Durch das eCoach Projekt wurde ich dabei unterstützt, die geeigneten digitalen Tools auszuwählen und einzusetzen.', 
        orientation: 'right'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
          {
        name: 'Stephanie Schwarz', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Stephanie/StephanieSchwarzSW.png',
        quote: 'Es gibt ein grosses digitales Angebot, das man in der Lehre einsetzen kann. Dabei wünsche ich mir, dass digitale Lehre die Präsenzlehre unterstützt und die Studierenden im Lernen fördert.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juni 2021",
          content:
            "Brainstorming mit Théoda vor Ort, um den Aufbau des Kurses festzulegen.",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/%282%29_Konzept_kelly-sikkema-v9FQR4tbIq8-unsplash.jpg" //TODO: Picture is not square
        },
        {
          index:1,
          title: "Juli – August 2021",
          content:
            "Regelmässiger Kontakt zwischen Théoda und mir, um den Kursraum aufzusetzen. Weil wir uns an der ersten Sitzung viel Zeit genommen haben und ein genaues Konzept erarbeitet haben, gab es danach fast keine Änderungen mehr. Die Zusammenarbeit lief sehr gut und effizient.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/%283%29_Gestalten%20des%20Kursraum_john-schnobrich-2FPjlAyMQTA-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "August 2021",
          content: //TODO: holprig?
            "Fertigstellen des Kursraumes mit Théoda und Sarah (Hilfsassistentin) -> Weitergabe von Wissen. Danach hatte ich fast nur noch beratende Funktion. Théoda hat sich grösstenteils selber um ihren Kurs gekümmert.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/%284%29_Aufsetzen%20des%20Kursraumes_artem-labunsky-c9p30E94OUU-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "November 2021",
          content:
            "Ende der Vereinbarung am 10.11.2021 -> bis dann sollte der Kursraum stehen, was er auch tat. Théoda hat sich nur noch sporadisch mit konkreten Fragen gemeldet, die oftmals sehr leicht zu lösen waren.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/%285%29_Abgabe%20des%20Projekts_kate-hliznitsova-jLNqwo5BE0Q-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/kursraum.png', orientation: 'left', title:'Beschrieb des Kursraumes', // TODO: change picture
          background:'#EFC1A9', text:'Das Proseminar ist in drei Teile gegliedert, die auf ILIAS in drei verschiedenen Farben dargestellt wurden. Jede Woche wurde die nächste Kachel mit einem Vorbereitungsauftrag für die Studierenden freigegeben. So konnten sie sich im Kursraum gut orientieren und wussten, welche Inhalte wo zu finden waren.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/02.jpg', orientation: 'right', title:'Gruppen',
          background:'#EAAC8B', text:'Im zweiten Teil des Proseminars mussten die Studierenden selber Videos produzieren (asynchrone Phase), dessen Inhalt unter anderem in der Präsenzveranstaltung diskutiert wurde. Dies geschah in zwei Gruppen. Im ILIAS wurden zwei Gruppen erstellt, wobei alle Studierenden nur die Gruppe sahen, in der sie eingeteilt waren.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/6/01.jpg', orientation: 'left', title:'Anleitungen',
          background:'#EFC1A9', text:'Als Hilfestellung für die Videoproduktion, wurden bestehende Anleitungen adaptiert und auf Englisch übersetzt. Die Anleitungen wurden in einer eigenen Kachel auf der ILIAS-Startseite dargestellt, damit sie schnell gefunden werden konnten. Es hat sich gelohnt - es wurde keine einzige Frage zu der Videoproduktion gestellt.' }
      ] //TODO: add pictures
    },


    'bildungserfolg-meritokratie-und-bildungsgerechtigkeit':{         
      index: 7,
      quotecolor: '#BCA324',                          //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#F2E9BA',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#E9D985',
      title: 'Bildungserfolg, Meritokratie und Bildungsgerechtigkeit',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Im Proseminar "Bildungserfolg, Meritokratie und Bildungsgerechtigkeit" werden veraltete Prüfungsformate über Bord geworfen.',
        'Nachdem sich die Studierenden in der Lernwerkstatt selbstständig die nötigen Kompetenzen angeeignet haben, erstellten sie in Arbeitsgruppen vertiefende Lernvideos.',
        'In diesem Kurs, der zum ersten Mal an der Uni Bern stattgefunden hat, wurden die Studierenden von einem eCoach begleitet und haben so technische, didaktische und gestalterische Unterstützung  bei der Videoerstellung bekommen.',
        'Doch auch neben einer intuitiven ILIAS-Seite und hervorragenden Lernvideos gibt es in diesem Projekt so einiges zu entdecken. Schaut vorbei und seht selbst!'
      ],
      introductionimg: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%281%29_Keyvisual_vitaliy-nqyK3NuwC6E-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Dr. Chantal Kamm', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Ercan/ChantalKammSW.png',     
        quote: 'Die Zusammenarbeit mit meinem eCoach Ercan Isik hat mir ermöglicht, eine alternative und innovative Form des Leistungsnachweises in Form von Lernvideos umzusetzen. Ercan hat mich nicht nur in den Kursvorbereitungen, sondern auch während des Semesters bei der technischen Umsetzung der studentischen Videoprojekte tatkräftig unterstützt. So sind tolle Endprodukte entstanden.', 
        orientation: 'right'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
          {
        name: 'Ercan Isik', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Ercan/ErcanSW.png',
        quote: 'Beim Unterrichten gibt es eine grosse Gestaltungs- und Methodenvielfalt.  Als eCoach möchte ich dabei helfen, diese zu erkennen und zu nutzen, denn von kreativem Unterricht profitieren nicht nur die Studierenden.',
        orientation: 'left'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "August 2021",
          content:
            "Chantal und ich haben gemeinsam das Semester geplant.",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%282%29_Semesterplannung_denise-jans-p0QUpDUX8X8-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "August 2021",
          content:
            "Die ILIAS-Seite wurde nach einigen Anpassungen und Überarbeitungen fertiggestellt.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%283%29_ILIAS-Kurs_andrik-langfield-ujx_KIIujRg-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "September 2021",
          content:
            "Das Merkblatt enthält alle wichtigen organisatorischen Informationen zur Videoerstellung.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%284%29_Merkblatt%20Videoerstellung_ccexpress.png"
        },
        {
          index: 3,
          title: "September 2021",
          content:
            "Unser Projekt ist mit der ersten Sitzung ins Rollen gekommen: Die Studierenden haben Gruppen gebildet und konnten mit der Videoerstellung starten.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%285%29_Erste%20Sitzung_william-moreland-GkWP64truqg-unsplash_ccexpress.jpeg"
        },
        {
          index: 4,
          title: "November 2021",
          content:
            "Das letzte Tutorial zur Videoerstellung wurde hochgeladen und somit die Lernwerkstatt vervollständigt.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%286%29_Lernwerkstatt_jakob-owens-CiUR8zISX60-unsplash_ccexpress.jpeg"
        },
        {
          index: 5,
          title: "November 2021",
          content:
            "Die ersten Lernvideos der Studierenden wurden gemeinsam angeschaut und diskutiert.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%287%29_Kolloquium_jeremy-yap-J39X2xX_8CQ-unsplash_ccexpress.jpeg"
        },
        {
          index: 6,
          title: "Dezember 2021",
          content:
            "Die letzten Lernvideos der Studierenden wurden angeschaut und diskutiert. Das Projekt ist damit zu Ende.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/%288%29_Letzte%20Sitzung_Graduation_vasily-koloda-8CqDvPuo_kI-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/01.png', orientation: 'left', title:'ILIAS-Kursraum', // orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'#F2E9BA', text:'Der Kursraum wurde so eingerichtet, dass er gerade für neue Studierenden sehr zugänglich ist. Durch viele Strukturen und farbliche Einteilungen haben wir eine übersichtliche und angenehme Lernumgebung geschaffen.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/merkblatt.png', orientation: 'right', title:'Merkblatt',
          background:'#E9D985', text:' Im Merkblatt stehen alle organisatorischen Informationen bezüglich der Videoerstellung. Sprich Fristen, Bedingungen, Vorgaben und Zielsetzung.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/03.png', orientation: 'left', title:'Videotutorials',
          background:'#F2E9BA', text:'In 5 selbst erstellten Videotutorials habe ich den Studierenden Schritt für Schritt die nötigen Grundlagen für das Erstellen eines Lernvideos vermittelt.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/7/manual.png', orientation: 'right', title:'Manual',
          background:'#E9D985', text:'Viele weitere Informationen zum Thema Videoproduktion konnten sich die Studierenden im extra angepassten Videomanual erarbeiten. Es basiert auf der Grundlage von Lars Lenze vom Institut für Sportwissenschaften.' }
      ] //TODO: add content pictures
    },
    'lernprogramm-schweinebestand':{
      index: 8,
      quotecolor:'#70783F',
      backgroundLight: '#D0D5AF',
      backgroundDark: '#B2BD7E',
      title: 'Smart Farming mit VR/360° Videos auf Schweinebestand',
      introductions:[
        'Willkommen auf der sauguten Projektseite für "360° Videos auf dem Schweinebestand" ', 
        'Die Veranstaltung "Schweinebestand" in der Tierklinik wurde bisher nur in den klassischen Unterrichtsmethoden gelehrt. Frontalunterricht mit wenigen praktischen Vor-Ort-Besuchen war Standard. Die COVID-19-Situation hat die Vor-Ort-Situation verunmöglicht. Aus diesem Grund hat VETSUISSE zwei eCoaches eingesetzt, um eine virtuelle Bestandesuntersuchung für angehende Tierärzte zu schaffen. In dem Digitalisierungsprojekt wurde Smart Farming als eines der ersten Projekte in der Schweiz umgesetzt.'

      ],
      introductionimg: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%281%29_Keyvisual_kenneth-schipper-vera-6y7jACxmhP8-unsplash_ccexpress.jpeg",
      lecturers:[
        {name: 'Dr. Alexander Grahofer', role: '', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Julio_Stephanie/AlexanderGrahoferSW.png',
        quote: 'Dank der fachlichen und kompetenten Unterstützung sowie der Motivation der eCoaches konnte dieses Lernprogramm vollumfänglich erstellt werden. Dadurch können diese 360°-Videos erfolgreich in der Lehre der Schweinemedizin integriert werden. Somit haben die Studierenden die Möglichkeit, virtuell eine Bestandsuntersuchung durchzuführen und die Beurteilung von Tiergesundheit, Tierumgebung und Management zu erlernen.',
        orientation: 'right'},
        {name: 'Ulrike Gerster', role: '', imgsrc2:"", imgsrc: 'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Julio_Stephanie/UlrikeGersterSW.png',
        quote: 'Die Arbeit mit unseren beiden eCoaches Stephanie und Julio hat mir sehr viel Spass gemacht, wir konnten sehr von Ihrem Know-how beim Dreh der Videos und beim Erstellen des Kursraumes profitieren. Ich persönlich konnte viel über die Möglichkeiten der Digitalisierung der Lehre lernen. Die Zusammenarbeit war ein Gewinn für mich persönlich und für die studentische Lehre.',
        orientation: 'left'},
        {name: 'Julio Estevan Durán Porras', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Julio_Stephanie/JulioDura%CC%81nPorrasSW.png',
        quote: 'Ich wünsche mir eine strukturelle Einheit pro Lehrveranstaltung, welche den Studierenden hilft, sich mit dem Wesentlichen zu beschäftigen. Digitale Lernprogramme sind optimal, um sich in der Präsenz mit der Materie reflektierend auseinanderzusetzen.',
        orientation: 'right'},
        {name: 'Stephanie Schwarz', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Julio_Stephanie/StephanieSchwarzSW.png',
        quote: 'Es gibt ein grosses digitales Angebot, das man in der Lehre einsetzen kann. Dabei wünsche ich mir, dass digitale Lehre die Präsenzlehre unterstützt und die Studierenden im Lernen fördert.',
        orientation: 'left'}
      ],
      timelineItems:[
        {
          index: 0,
          title: "Konzept (Juli 2021)",
          content:
            "Rahmen setzen und Ressourcenplanung durch die eCoaches. Dabei ging es darum, die Erwartungen abzuholen und ein gemeinsames Ziel abzusprechen. ",
          src: "https://tinucdn.fra1.digitaloceanspaces.com/vipro-assets/8/%282%29_Konzept%20%28ca.%20Mitte%20Juli%29_med-badr-chemmaoui-ZSPBhokqDMc-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Kauf von InstaPro II (Juli 2021)",
          content:
            "Nach gemeinsamer Besprechung und Evaluation von verschiedenen 360° Kameras wurde die Insta Pro II gekauft. Die Inbetriebnahme führten die eCoaches durch.",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%283%29_Kauf%20von%20Insta%20Pro%20II_fotis-fotopoulos-6L4ALETAORU-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "Videos drehen (September 2021)",
          content:
            "Die ersten Bestände wurden bereits in den Semesterferien gedreht. Das Handling von der Kamera war zu Beginn noch gewöhnungsbedürftig, am Schluss waren wir ein eingespieltes Team.",
          src: "https://tinucdn.fra1.digitaloceanspaces.com/vipro-assets/8/%284%29_Videos_drehen_sam-mcghee-KieCLNzKoBo-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "Videos bearbeiten/ stitchen (November 2021)",
          content:
            "Das Stitchen von den Videos hat sich als eine schwierige Aufgabe erwiesen. Nach dem Organisieren von einem leistungsstarken Computer war diese Aufgabe gemeistert.",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%284%29_Videos_drehen_sam-mcghee-KieCLNzKoBo-unsplash_ccexpress.jpeg"
        },
        {
          index: 4,
          title: "Kapazitätsmanagement / Datamanagement (September – November 2021)",
          content:
            "Kurzerhand musste einen 18 TB Server organisiert werden. Nach dem Einrichten mit dem IT-Dienst konnten die Videos ordnungsgemäss abgelegt werden. ",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%286%29_Datenmanagement_taylor-vick-M5tzZtFCOfs-unsplash_ccexpress.jpeg"
        },
        {
          index: 5,
          title: "ILIAS Proberaum mit Implementierung von 360 Grad Videos (November 2021)",
          content:
            "Nach vielem Ausprobieren und enger Zusammenarbeit mit dem iLUB ist uns als erstes Projekt an der Universität Bern gelungen 360° Videos im ILIAS zu integrieren. ",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%287%29_peter-stumpf-h-ySkoCRzhs-unsplash_.jpeg"
        },
        {
          index: 6,
          title: "Kontinuierliche Verbesserung von ILIAS Kursraum (Dezember 2021)",
          content:
            "Ständiger Austausch mit den Dozenten ermöglichten ein Selbstlernkurs mit didaktischer Kohärenz ",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%288%29_Kontinuierliche%20Verbesserung%20von%20ILIAS%20Kursraum_jungwoo-hong-cYUMaCqMYvI-unsplash.jpeg"
        },
        {
          index: 7,
          title: "Übergabe Projekt an VETSUISSE Team (Dezember 2021)",
          content:
            "Mit ILIAS-Anleitungen und dem Merkblatt ist das VETSUISSE aufgeklärt in die Phase 2 gegangen",
          src: "https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/%289%29_Projektu%CC%88bergabe_lina-trochez-ktPKyUs3Qjs-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/8/1.png', orientation: 'left', title:'Allgemeine Infos',
          background:'#B2BD7E', text:'Der neu gestaltete ILIAS-Kursraum verschafft nun einfachen und zeitlosen Zugang zu allen Schweinebeständen. Als LMS (Learning Management System) ist ILIAS die zentrale Anlaufstelle für alle digitalen Aspekte der Vorlesung. Über den Selbstlernkurs können die Bestände virtuell besichtigt und direkte Lernmöglichkeiten mittels Fragen genutzt werden.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/8/2.png', orientation: 'right', title:'360° Videos Seite im Lernmodul',
          background:'#D0D5AF', text:'Die 360° Videos wurden via SwitchCast in den Selbstlernkurs eingepflegt. Dabei wurden die Videos direkt mit dem Insta Pro Stitcher bearbeitet, sodass diese SwitchCast konform hinaufgeladen werden konnten. Mit einer durchschnittlichen Kapazität von rund 50 GB pro Video haben wir nach einer effizienten Lösung gesucht. Mittels Erfahrungen vom iLUB und eCoaches konnte eine gute Lösung gefunden werden, sodass die Effizienz durchaus gesteigert werden konnte, dennoch für weitere Projekte (z. B. VR) genug Bearbeitungsmöglichkeiten herrschen.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/8/merkblatt.jpg', orientation: 'left', title:'Merkblatt und ILIAS Anleitung für Phase 2',
          background:'#B2BD7E', text:'Das übersichtliche Merkblatt gibt einen kleinen Einblick über das gesamte Projekt. Es dient zur Projektvorstellung an Externe oder Interessierte. In Kombination mit dem unterstehenden Introvideo sind die grundlegenden Lösungen und Arbeitsschritte abgebildet. ' }      ], // TODO: change picture
      videosrc: 'https://tinucdn.fra1.cdn.digitaloceanspaces.com/vipro-assets/8/Introvideo.mp4'
    }

    ,
    'tutorium-nachhaltige-entwicklung':{         
      index: 9,  
      quotecolor: '#4C6B4D',                       //Jedes Projekt hat eine Nummer von 1-9, die Nummern sind identisch mit der id: eigenschaft auf den Würfeln
      backgroundLight: '#9EC5A0',       // Jedes Projekt hat eine eigenes Farbkonzept. Die Projektseite selbst hat dann jeweils einen helleren und einen dunkleren Farbton
      backgroundDark: '#749C75',
      title: 'K1 Tutorium Nachhaltige Entwicklung',       // Der Titel wird so auf der Projektseite Angezeigt
      introductions:[
        'Das Tutorium Nachhaltige Entwicklung führt pro Jahrgang jeweils über hundert Studierende zum Minor Nachhaltiger Entwicklung auf dem Bachelor-Niveau ein. Mit der gemeinsamen Zusammenarbeit des Lehre-Teams und dem eCoach wurde die Lehrveranstaltung und der Kursraum grafisch und didaktisch aufgewertet.',
        'Werfen Sie doch einen weiteren Blick, bei der Sie die Umsetzung des Projekts und deren Timeline selbst sehen können und hören Sie die Stimmen der Projektbeteiligten. Wir, das Team Lehre Nachhaltige Entwicklung und der eCoach, möchten uns ganz herzlich bei Ihnen für Ihre Zeit und Aufmerksamkeit bedanken.'
      ],
      introductionimg: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%281%29_Keyvisual_emma-gossett-B645igbiKCw-unsplash_ccexpress.jpeg",      // die Quelle zu dem ersten Bild ganz rechts
      lecturers:[                                                                                                                                           // hier kann einer oder mehrere Dozenten aufgelistet werden. Die Eigenschaft orientation sagt aus, ob der Text rechts oder links des Bildes ist
        {name: 'Claudia Staub & Prof. Dr. Thomas Hammer', role: '', imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/_SW-Portraits/Minh/ClaudiaStaubandThomasHammer.jpg', imgsrc2:'true',  //TODO: von wem genau?  
        quote: 'Dank unserem eCoach lernen wir verschiedene Möglichkeiten kennen, ILIAS übersichtlicher und attraktiver zu gestalten. Die gemachten Erfahrungen können wir nun auf weitere Veranstaltungen übertragen, was ein grosser Mehrwert für unsere ganze Ausbildung darstellt.', 
        orientation: 'right'},
        {name: 'Jonas Chastonay', role: '', imgsrc2:"", imgsrc: 'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Minh/JonasSW.png',
            quote: 'Ich freue mich, dass das Ergebnis des eCoach-Projekts in Zukunft auf andere Veranstaltungen angewendet werden kann.',
        orientation: 'left'},                                                                                                                                          // auch hier kann einer oder mehrere eCoaches aufgelistet werden. Die Eigenschaften sind identisch zu denen der Dozierenden
          {
        name: 'Khanh-Minh Lam', role: 'eCoach', imgsrc2:"", imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Portraits/Minh/KhanhMinhLamSW.png',
        quote: 'Durch eine visuell ansprechende Gestaltung der Lehre kann das Interesse, die Aufmerksamkeit und Motivation der Studierenden, sich mit der Materie auseinanderzusetzen, gezielt gesteigert werden – ein Win-Win für alle!',
        orientation: 'right'}
      ],
      timelineItems:[                                                                                                                                      // Eine liste mit allen Einträgen der Timeline. Es kann eine beliebige anzahl an Einträgen gemacht werden.
        {
          index: 0,             // der Index sollte bei 0 starten und die weiteren einträge müssen durchgehend nummeriert sein
          title: "Juni 2021",
          content:
            "Gemeinsam mit dem Team Lehre Nachhaltiger Entwicklung und dem eCoach wurden die ersten Termine ausgemacht und eine Abklärung der Wünsche, Bedürfnisse und der Machbarkeit fand statt. Für das Konzept fand ein erstes Brainstorming für die Gestaltung des ILIAS-Kursraumes statt. ",
             src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%282%29_Planung%20und%20erste%20Besprechungen_glenn-carstens-peters-RLw-UC03Gwc-unsplash_ccexpress.jpeg"
        },
        {
          index:1,
          title: "Juni -Juli 2021",
          content:
            "Nach weiteren Besprechungen mit anschliessendem Feedback und neuen Inputs wurde ein erster Prototyp des Layouts des ILIAS-Kursraums erstellt. Nach der Besprechung des ersten Prototyps kommen beiderseits neue Ideen auf, welche in der Erstellung eines zweiten Prototyps berücksichtigt wird.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%283%29_Umsetzung%20Prototypen%20und%20Besprechungen_sigmund-4UGmm3WRUoQ-unsplash_ccexpress.jpeg"
        },
        {
          index: 2,
          title: "August 2021",
          content:
            "Ein Projekt zur Aufwertung eines Kursraumes und der Online-Lehre ist ein wandelndes Vorhaben. Dementsprechend entstehen nach dem Erreichen kleiner Meilensteine immer wieder neue Ideen und damit neue Ergänzungen und Anpassungen beiderseits.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%284%29_Erga%CC%88nzungen%20und%20Anpassungen_ross-sneddon-sWlDOWk0Jp8-unsplash_ccexpress.jpeg"
        },
        {
          index: 3,
          title: "August bis 10. September 2021",
          content:
            "Ende August war der Prototyp des neuen ILIAS-Auftritts bereit für die Programmierung und Gestaltung. Mit der Aufschaltung kurz vor dem Semester war der grösste Meilenstein des Projekts erreicht.",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%285%29_Finalisierung%20und%20Online%20schalten_kelly-sikkema-fZirmwarM0E-unsplash_ccexpress.jpeg"
        },
        {
          index: 4,
          title: "September – Dezember 2021",
          content:
            "Die Aktualisierung der Inhalte erfolgte durch das Team Lehre - die Auffrischung des Layouts und der Benutzerfreundlichkeit erfolgte durch den eCoach. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%286%29_Optimierungen_olav-ahrens-rotne-4Ennrbj1svk-unsplash_ccexpress.jpeg"
        },
        {
          index: 5,
          title: "Dezember 2021",
          content:
            "Am Ende des Projekts wurde ein Merkblatt und ein paar Videotutorials erstellt, sodass das Wissen vom eCoach weiter an die Dozierenden weitergegeben werden kann und das Projekt sich langfristig und nachhaltig lohnt. ",
          src: "https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%287%29_U%CC%88bergabe_jose-luis-cofre-3lBZ6ZJEmzg-unsplash_ccexpress.jpeg"
        }
      ],
      contents:[ // hier ist eine Liste von beliebiger Länge, in der die weiteren Impressionen des Projektes wiedergegeben werden können. Jeder Eintrag räpresentiert ein Bild mit dem seitlich angebrafchten Text. orientation 
        { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%287.1%29_Screenshot%20Pinnwand%20Ausschnitt.png', orientation: 'left', title:'Pinnwand',// orientation left bedeutet, dass das Bild auf der linken seite ist. Bei Background muss abwechslungsweise der hellere oder der dunklere farbton angegeben werden.
          background:'', text:'Für eine schlichte und einfache Kommunikation kann eine Art Pinnwand als Kommunikationstool für Studierende verwendet werden. Eine Pinnwand hat folgende Vorzüge: Sie ist weniger aufdringlich und zeitintensiv als eine wöchentliche ILIAS-Nachricht und zudem sieht man sie jederzeit beim Betreten des Kursraums.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%287.2%29_Sitzungskacheln%20Ausschnitt.png', orientation: 'right', title:'Sitzungskacheln',
          background:'#9EC5A0', text:'Statt Sitzungen in einer Liste darstellen zu lassen, kann man mit relativ wenig Aufwand die  Sitzungstermine grafisch aufgewertet in einem Kachelsystem darstellen lassen.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%287.3%29_Ordner%20%28Screenshot%29%20Ausschnitt.png', orientation: 'left', title:'Ordner',
          background:'', text:'Im Tutorium Nachhaltige Entwicklung gibt es viele verschiedene Themen. Für diesen Jahrgang gab es rund 14 verschiedene Themengebiete. Diese kann man mit Bildern wiederum mit dem Kachelsystem illustrieren.' },
          { imgsrc:'https://vipro.fra1.cdn.digitaloceanspaces.com/Content/9/%287.4%29_Integration%20eines%20PDF-Readers%20Ausschnitt.png', orientation: 'right', title:'Integration eines PDF-Readers',
          background:'#9EC5A0', text:'Falls Ihre Studierenden viele Dokumente lesen müssen, wäre die Integration eines PDF-Readers in ILIAS sicherlich in Betracht zu ziehen. Mit diesem können Sie einerseits die Lesequote steigern, dank der noch einfacheren Zugänglichkeit, andererseits profitieren die Studierenden auch davon, dass sie nicht jedes einzelne Dokument herunterladen müssen und sich vor lauter Dokumente verlieren.' }
      ]
    },
  }
  }

const mutations = {
    mute(state){
      state.muted = true
    },
    unMute(state){
      state.muted = false
    },
    pause(state){
      state.paused = true
    },
    unPause(state){
      state.paused = false
    },
    
    turnFront(state){
      state.cubes[0].orientation = "front"
    },
    turnLeft(state){
      state.cubes[0].orientation = "left"
    },
    turnTop(state){
      state.cubes[0].orientation = "top"
    },
    turnRight(state){
      state.cubes[0].orientation = "right"
    },
    focus (state, id){
      //state.cubesBefore = state.cubes.slice(0,3)
      //state.cubesAfter = state.cubes.slice(4)
      state.cubesBefore = state.cubes.splice(0,id-1)
      state.cubesAfter = state.cubes.splice(1)
      state.menuViewUntouched = false;
    },
    unFocus (state){
      state.cubes = state.cubesBefore.concat(state.cubes)
      state.cubes = state.cubes.concat(state.cubesAfter)
    },
    
    expandMenu(state) {
      state.menuView = 'big'
      this.commit('unFocus')
    }, 
    reduceMenu(state, id) {
      state.menuView = 'reduced'
      this.commit('focus', id)
    }

  }

export default new Vuex.Store({
  state,
  mutations
})

Vue.use(Vuex)