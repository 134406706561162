<template>
  <div class="row">
    <div class="grid-item left timelinecontainer" >
      <div class="textcontent">
        <h3>{{currentItem.title}}</h3>
        <p style="margin-top: 20px;">{{currentItem.content}}</p>
      </div>
      <div class="lines">
        <div v-for="item in items" :key="item.index">
          <div v-if="item.index > 0" class="line"  :style="{height : lineHeight + 'px'}"></div>
          <div class="dot full" v-if="item.index == currentItemIndex" @click="currentItemIndex = item.index"></div>
          <div class="dot" v-if="item.index != currentItemIndex" @click="currentItemIndex = item.index"></div>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <transition name="fade">
        <img :src="currentItem.src"  data-toggle="tooltip" data-placement="bottom" title="goTop" alt="" class="img" style="margin-bottom: -5px;">
      </transition>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Timeline',
  components:{

  },
  props:{
    items:Array
  },
  data() {
    return {
      currentItemIndex: 0
    };
  },
  computed: {
  currentItem: function () {
    return this.items[this.currentItemIndex];
  },
  lineHeight: function () {
    return 300 / (this.items.length -1) -30
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.row{
  display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.img{
  width: 100%;
}
.dot{
  width:18px;
  height:18px;
  border-radius:15px;
  margin-right: 0px;
  border: 6px solid;
  border-color: black;
  cursor: pointer;
}
.full{
  background: black;
}
.line{
  
  width:6px;
  background: black;
  margin-left:12px;
  
}
.textcontent{
  float: left;
  overflow: hidden;
  width: 70%;

}
.lines{
  float: right;
  
 
  
}
.left{
  padding: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
}
  @media (min-width: 1067px) {
    .timelinecontainer{
          height: 29vw;
        }
  }
  @media (min-width: 1867px) {
    .timelinecontainer{
          height: 30vw;
        }
  }

.timelinecontainer{
    overflow: auto;
    scrollbar-color: black rgba(0,0,0,0);
    scrollbar-face-color: #367CD2;
    scrollbar-shadow-color: green;
    scrollbar-highlight-color: #FFFFFF;
    scrollbar-3dlight-color: #FFFFFF;
    scrollbar-darkshadow-color: #FFFFFF;
    scrollbar-track-color: #FFFFFF;
    scrollbar-arrow-color: #FFFFFF;
}
  .timelinecontainer::-webkit-scrollbar {
    width: 12px;
}
  .timelinecontainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: rgba(0,0,0,0)
}
 
/* Handle */
.timelinecontainer::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: black;  
}

</style>