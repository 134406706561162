<template >
  <div class="project" :style="{background : project.backgroundDark}" >
    <main>
      <section class="introduction"  ref="introduction">
        <div class="grid-item" >
          <img :src="project.introductionimg" alt="" class="img" style="margin-bottom: 0px;">
        </div>
          <div class="textcontent grid-item" style="margin-bottom: 10px;">
            <h1>{{project.title}}</h1>

            <p v-for="introduction in project.introductions" :key="introduction" v-html="introduction">
            </p>
          
          </div>
      </section>
      <section class="quotes" >
        <div class="" :style="{background: project.backgroundLight}">
          <div class="textcontent quotecontainer" ref="who">
            <div v-for="lecturer in firstPortraits" :key="lecturer.name" >
              <Portrait  :quote="lecturer.quote"
                  :name="lecturer.name" :role="lecturer.role" :imgsrc="lecturer.imgsrc" :orientation="lecturer.orientation" :color="project.quotecolor" :imgsrc2="lecturer.imgsrc2" />
            </div>
          </div>  
        </div>
        <div class="" v-if="project.lecturers.length > 2">
          <div class="textcontent quotecontainer" ref="who2">

            <div v-for="ecoach in secondPortraits" :key="ecoach.name">
              <Portrait :quote="ecoach.quote"
                  :name="ecoach.name" :role="ecoach.role" :orientation="ecoach.orientation" :imgsrc="ecoach.imgsrc" :color="project.quotecolor" imgsrc2=""/>
            </div>
          </div>
        </div>
      </section>
      <section class="timeline" ref="timeline" style="margin-bottom: -5px;">
         <Timeline :style="{background: timelinecolor}" :items="project.timelineItems"/>

      </section>

    </main>
    
    
    <div class="article" ref="content2">
      
          
      
      <section class="content" v-for="content in project.contents" :key="content.text" :style="{background: content.background}" >
        <div class="grid-item" v-if="(content.orientation == 'left') && !leftOrientation" >
          <img :src="content.imgsrc" alt="" class="img" :style="{marginTop: marginModePositive, marginBottom: marginModeNegativeQuentin}" >
        </div>
        <div class="grid-item" style="margin-bottom:-5px;">
          
          <div class="textcontent" >
            <h3 style="padding-top: 70px;">{{content.title}}</h3>
            <div v-html="content.text">

            </div>
          </div>
        </div>
        <div class="grid-item" v-if="(!(content.orientation == 'left')|| leftOrientation) ">
          <img :src="content.imgsrc" alt="" class="img" :style="{marginBottom: marginModeNegative, marginTop: marginModePositiveQuentin}">
        </div>
      </section>
      <div class="" v-if="this.project.hasOwnProperty('videosrc')" >
        <video :src="project.videosrc"  
          class="video"
          controls> </video>
      </div>
      
      
    </div>
  </div>
</template>

<script>


import Timeline from '@/components/Timeline.vue'
//import IntersectionObserver from '@/components/IntersectionObserver.vue'
import Portrait from '@/components/Portrait.vue'

export default {
  name: 'Project',
  components: {
    Timeline,
    Portrait,
    //IntersectionObserver
  },
  data() {
    return {
      isIntersectingElement: false,
    };
  },
  methods: {
    onIntersectionElement(value, newOrientation) {
      this.isIntersectingElement = value;
      this.onTurn(newOrientation)
    },
    onTurn(newOrientation){
      this.$store.commit('turn'+newOrientation)

    }
  },
  computed:{
    project: function () {
      return this.$store.state.projects[this.$route.params.id]
    },
    timelinecolor: function () {
      if (this.project.lecturers.length > 2){
        return this.project.backgroundLight
      }else{
        return this.project.backgroundDark
      }
    },
    firstPortraits: function(){
      if(this.project.lecturers[0].imgsrc2 == 'true')
        return this.project.lecturers.slice(0,1)
      return this.project.lecturers.slice(0,2)
    },
    secondPortraits: function(){
      if(this.project.lecturers[0].imgsrc2 == 'true')
        return this.project.lecturers.slice(1,3)
      return this.project.lecturers.slice(2,4)
    },
    marginModeNegative: function(){
      const project = this.$store.state.projects[this.$route.params.id]
      if(project.index == 9 || project.index == 5){
        return '-5px'
      }
      return '0px'
    },marginModeNegativeQuentin: function(){
      const project = this.$store.state.projects[this.$route.params.id]
      if(project.index == 5){
        return '-5px'
      }
      return '0px'
    },
    marginModePositive: function(){
      const project = this.$store.state.projects[this.$route.params.id]
      if(project.index == 9){
        return '5px'
      }
      return '0px'
    },
    marginModePositiveQuentin: function(){
      const project = this.$store.state.projects[this.$route.params.id]
      if(project.index == 5){
        return '5px'
      }
      return '0px'
    },
    leftOrientation: function(){
      //const mediaQuery = window.matchMedia('(max-width: 767px)')
      //return mediaQuery.matches
      if(window.innerWidth > 1068){
        return false
      }
      return true
    },
    test: function(){
      return window.innerWidth
    }
  },
  mounted(){
    window.scrollTo(0,0)
    const introduction =  this.$refs.introduction
    const who = this.$refs.who
    const who2 = this.$refs.who2
    const timeline = this.$refs.timeline
    const content = this.$refs.content2
    
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75
    }
    const introductionHandler = (entries) => {
      if (entries[0].isIntersecting) {
        this.$store.commit('turnFront')
        console.log('introduction')

      }
    }
    const whoHandler = (entries) => {
      if (entries[0].isIntersecting) {
        this.$store.commit('turnTop')
        console.log(who)
      }
    }

    const timelineHandler = (entries) => {
      if (entries[0].isIntersecting) {
        this.$store.commit('turnLeft')
        console.log('timeline')
      }
    }
    const contentHandler = (entries) => {
      if (entries[0].isIntersecting) {
        this.$store.commit('turnRight')
        console.loc(content)
      }
      
    }
    
    const introductionObserver = new IntersectionObserver(introductionHandler, options)
      introductionObserver.observe(introduction)
    const whoObserver = new IntersectionObserver(whoHandler, options)
      whoObserver.observe(who)

    const timelineObserver = new IntersectionObserver(timelineHandler, options)
      timelineObserver.observe(timeline)
    const contentObserver = new IntersectionObserver(contentHandler, {threshold: 0.2})
      contentObserver.observe(content)
    
        const who2Handler = (entries) => {
      if (entries[0].isIntersecting) {
        this.$store.commit('turnTop')
        console.log('who2')
      }
    }
    const who2Observer = new IntersectionObserver(who2Handler, options)
      who2Observer.observe(who2)
  }
}

</script>

<style scoped>
  .introduction{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    margin-bottom:-5px;
    overflow:hidden;
  }

  .content{
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    margin-bottom:-5px
  }
  
  
  
  
  h1{
    margin-top:0px;
  }
  .img{
    width: 100%;
    margin:0;
  }
  .textcontent{
    
    padding-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
    overflow: auto;
    scrollbar-color: black rgba(0,0,0,0);
    scrollbar-face-color: #367CD2;
    scrollbar-shadow-color: green;
    scrollbar-highlight-color: #FFFFFF;
    scrollbar-3dlight-color: #FFFFFF;
    scrollbar-darkshadow-color: #FFFFFF;
    scrollbar-track-color: #FFFFFF;
    scrollbar-arrow-color: #FFFFFF;
  }
  
  @media (max-width: 767px) {
    .textcontent{
          padding-left: 30px;
        }
  }

  @media (min-width: 1067px) {
    .textcontent{
          height: 35vw;
        }
  }
  @media (min-width: 1867px) {
    .textcontent{
          height: 40vw;
        }
  }
  .textcontent::-webkit-scrollbar {
    width: 12px;
}
  .textcontent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background: rgba(0,0,0,0)
}
 
/* Handle */
.textcontent::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0px;
    background: black;  
}
  
.article{
    position: relative;
    margin: 0 auto;
}
  .project {
    margin: 0px;
    margin-left: 250px;   
  }
 @media (max-width: 767px) {
        .project {
          margin-left: 0px;
        }
      }

  .grid-item:before{
    content:"";
    float: left;
    padding-top: 100%;
  }

  .textcontent.quotecontainer{
    height: auto;
  }
  video{
    width: 80%;
    margin:10%;
  }
</style>
