import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  watch:{
    '$route.path':{
      handler: function(to){
        if(to == '/'){
          this.$store.commit('turnFront')
          this.$store.commit('expandMenu', 0)
        }
        else{
          this.$store.commit('reduceMenu', this.$store.state.projects[to.substring(1)].index)
          
        }
      },
      immediate:true
      
    }
  },
  store,
}).$mount('#app')
