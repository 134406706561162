<template>
  <div id="app">
    
    <Video v-if="menuView != 'reduced'" ref="videoComponent"></Video>
      <nav v-if="menuView == 'reduced'">
        <img src="@/assets/Menu.png" alt="" height="40px" v-on:click="toggleMenu">
      </nav>
              <div  v-if="menuView != 'reduced'" class="aboutleft">
          <div>
            <a v-on:click="goToTop" style="cursor:pointer">
              PROJEKTE 2021<br>eCoaches<br>für Digital Skills<br>in der Lehre
            </a>
          </div>
          <div class="uni" style="font-size: 0.8em; padding-top: 30px">
            <a href="https://www.gutelehre.unibe.ch/ecoaches" target="_blank">
              Universität Bern <br>
              swissuniversities
            </a>
          </div>
        </div>
      
      
      <div class="menu" :class="menuView">
      
        
      
      <transition-group name="list-complete" tag="p" id="overview" style="text-align:center;">
        <div
          v-for="cube in cubes"
          v-bind:key="cube.id"
          class="list-complete-item"
        >
          <Cube :cube="cube"/>
          
        </div>
      </transition-group>
      <transition name="fade">
        <img src="@/assets/Menu2.png" v-on:click="toggleMenu" v-if="menuView == 'reduced'" class="menubutton" style="cursor: pointer;">
      </transition>

    </div>
            <div  v-if="menuView != 'reduced'" class="aboutbottom">
          <div>
            <a v-on:click="goToTop">
              PROJEKTE 2021<br>eCoaches<br>für Digital Skills<br>in der Lehre
            </a>
          </div>
          <div class="uni" style="font-size: 0.8em; padding-top: 30px">
            <a href="https://www.gutelehre.unibe.ch/ecoaches" target="_blank">
              Universität Bern <br>
              swissuniversities
            </a>
          </div>
        </div>
    <transition name="fade">
      <router-view v-if="menuView == 'reduced' | true" style="z-index:200;"/>
    </transition>
    

    <div class="footer" v-if="menuView != 'reduced'">
      <img src="https://vipro.fra1.cdn.digitaloceanspaces.com/Content/eCoach21_Logo_weiss.png" alt="" width="180px" v-if="menuView != 'reduced'" >
      <p ><b>© WEBCREW · eCoaches 21</b><br>Martin Widmer · Jessica Riediker · Khanh-Minh Lam<br>Julio Durán · Thomas Blümli</p>
    </div>
  </div>
  
</template>

<script>


import Cube from './components/Cube.vue' 
import Video from './components/Video.vue'

export default {
  name: 'App',
  components: {
    Cube,
    Video
  },
  methods: {
    toggleMenu: function() {
      this.$router.push({path:'/'})
      //this.$store.commit('toggleMenu')    
    },
    goToTop: function(){
      window.scrollTo(0,0)
      this.$refs.videoComponent.replay()
    }
  },
  computed: {
    cubes() {
      return this.$store.state.cubes;
    },
    menuView() {
      return this.$store.state.menuView;
    },
    menuViewUntouched(){
      return this.$store.state.menuViewUntouched;
    },
    expanded(){
      return true;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


.footer{
  bottom: -100px;
  color: #b2b3b5;
  font-size: 0.8em;
  width: 400px;
  text-align: center;
  padding-bottom: 50px;
  margin: auto;
}
html, body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
  background: #181c2a;
}
.logo{
  position: relative;
  margin: auto;
  width: 180px;
  bottom: 50px;
  

}

 @media (max-width: 767px) {
        .menu.reduced {
          display: none;
        }
      }
  nav{
    
    width: 100%;
    position: fixed;
    top: 0px;
    padding:10px;
    z-index: 999;
    
  }
  @media (min-width: 767px) {
        nav {
          display: none;
        }
      }
  @media (min-width: 1200px){
    .aboutbottom{
      display: none;
    }
    .logo{
      margin-left: auto;
      margin-right:auto;
      transform: translateX(-15px)
    }
  }

.aboutbottom{
  position: relative;
  margin-left: 100px;
  margin-top: 0px;
  width: 150px;
  padding-bottom: 80px
}

@media (max-width: 1200px){
    .aboutleft{
      display: none;
    }
    .logo{
      left: 85px
    }
  }

.aboutleft{
  position: absolute;
  margin-left: 5%;
  margin-top: 50px;
  width: 150px;
}

.aboutbottom > div> a {
  color: #b2b3b5;
  padding-bottom: 100px;
  text-decoration: none;
}

.aboutbottom > div > a:hover{
  color: white;
}

.aboutleft > div > a {
  color: #b2b3b5;
  text-decoration: none;
}

.aboutleft > div > a:hover{
  color: white;
  cursor: pointer;
}
.menubutton{
  width: 140px;
  margin: 50px;
  margin-top: 0;
  padding-top: 0px;
  
}
h1{
  font-size: 2.33em;
  font-family: 'Varela', sans-serif;
}

p, span, a {
  font-size: 1em;
}


#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  position: relative;
  min-height: 100vh;
}

.menu{
 z-index: 500;


}
.menu.reduced {
  position: fixed;
  max-width: 700px;
  margin: 0 auto;
}

.menu.big{
  height: 100%;
  max-width: 700px;
  margin: 0 auto;
}

/*#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/

.list-complete{
  text-align: center;
  position: relative;
  top: 0px;
  margin: 0 auto;
  width: auto;
}
.list-complete-item {
  transition: all .5s;
  display: inline-block;
  margin:0px auto 0px auto;
  /*margin-right: 10px;*/
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-500px);
}
.list-complete-leave-active {
  position: absolute;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.uni{
  font-size: 0.8em;
 
}
</style>
